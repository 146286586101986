@import './../../../../scss/theme-bootstrap';

$basic-grid-gutter: 12px;

.basic-grid {
  @include pie-clearfix;
  padding-#{$rdirection}: $basic-grid-gutter;
  &__item {
    padding: 0 0 $basic-grid-gutter;
    .basic-grid--small-cols-2 &,
    .basic-grid--small-cols-3 &,
    .basic-grid--small-cols-4 & {
      float: #{$ldirection};
      padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    @include breakpoint($medium-up) {
      float: #{$ldirection};
      width: 33.333%;
      padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
      .basic-grid--medium-cols-1 & {
        float: none;
        width: auto;
        padding: 0 0 $basic-grid-gutter;
      }
      .basic-grid--medium-cols-2 & {
        width: 50%;
      }
      .basic-grid--medium-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--medium-cols-4 & {
        width: 25%;
      }
    }
  }
  &__header {
    @include swap_direction(margin, 0 0 20px 0);
  }
  &__subheader {
    @include swap_direction(margin, 0 0 20px 0);
  }
}
